import { AlgoliaClinicV2 } from '@/types/algolia-types';
import { ROUTES } from '@/routes';

export const makeClinicSlug = (clinic: AlgoliaClinicV2, specialityLabel?: string, specialityId?: string, doctorId?: string) => {
  const baseSlug = `${ROUTES.CLINIC.pathname}/${clinic?.slug}`;

  if (!specialityLabel) {
    return baseSlug;
  }

  const specialityPart = `?speciality=${encodeURIComponent(specialityLabel)}`;
  const specialityIdPart = specialityId ? `&specialityId=${specialityId}` : '';
  const doctorIdPart = doctorId && specialityId ? `&doctorId=${doctorId}` : '';

  return `${baseSlug}${specialityPart}${specialityIdPart}${doctorIdPart}`;
};
