import Image from 'next/image';
import { ROUTES } from '@/routes';
import { capitalizeFirstCharInString } from '@/utils/string';
import { AggregatorDoctor, AggregatorSpeciality } from '@/types-aggregatore';
import Link from 'next/link';
import { DAVINCI_CDN } from '@/costants/defaultValues';
import PriceSection from '@/app/_components/card/cardBody/priceSection';

type ServiceProps = {
  specialityItem: AggregatorSpeciality;
  slug?: string;
  isLicensedClinic: boolean;
  isLoadingAvailabilities: boolean;
  hasChangedSelectedSlot: boolean;
  doctorWithAvailabilities?: AggregatorDoctor;
  price?: number;
};

const Service = ({
  hasChangedSelectedSlot,
  specialityItem,
  doctorWithAvailabilities,
  slug,
  isLicensedClinic,
  isLoadingAvailabilities,
  price,
}: ServiceProps) => {
  const { label, macroSpeciality, doctors, hasDynamicPrice, availablePayments = [] } = specialityItem;

  return (
    <div className="flex flex-col gap-y-4" data-cy="card-service">
      {(label || macroSpeciality) && (
        <>
          <div className="flex flex-col">
            <h3 className="text-lg font-semibold text-primary-active">{capitalizeFirstCharInString(label || macroSpeciality)}</h3>
            <div>
              <div className="inline-flex items-center gap-x-1">
                {slug && (
                  <Link href={`${ROUTES.CLINIC.pathname}/${slug}`} legacyBehavior>
                    <a href={`${ROUTES.CLINIC.pathname}/${slug}`} className="text-sm text-neutral-lighter underline">
                      Vedi tutte le prestazioni
                    </a>
                  </Link>
                )}
              </div>
            </div>
            {isLicensedClinic && (
              <div className="mt-5 inline-flex items-center gap-x-2">
                <div className="h-7 w-7">
                  <Image src={`${DAVINCI_CDN}/elty/stethoscope-neutral-darker.svg`} width={22} height={22} alt="stethoscope" />
                </div>
                <div className="text-base font-medium text-neutral-darker">
                  {doctorWithAvailabilities && `${doctorWithAvailabilities?.name} ${doctorWithAvailabilities?.surname}`}
                </div>
              </div>
            )}
          </div>
        </>
      )}

      <PriceSection
        availablePayments={availablePayments}
        hasChangedSelectedSlot={hasChangedSelectedSlot}
        hasDynamicPrice={hasDynamicPrice}
        isLoadingAvailabilities={isLoadingAvailabilities}
        isLicensedClinic={isLicensedClinic}
        price={price}
      />
    </div>
  );
};

export default Service;
