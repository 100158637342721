import Collapsible from '@/app/_components/collapsible';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { makeClinicSlug } from '@/utils/shared';
import { AlgoliaClinicV2 } from '@/types/algolia-types';
import SimpleNavLink from '@/app/_components/simpleNavLink';

type CardFooterProps = {
  item: AlgoliaClinicV2;
};

const CardFooter = ({ item }: CardFooterProps) => {
  return (
    <Collapsible
      btnOpen={
        <div className="inline-flex w-full items-center justify-between">
          <span className="text-xs font-medium text-neutral-darker">Visualizza i dettagli</span>
          <span className="h-5 w-5 fill-primary">
            <ChevronDownIcon />
          </span>
        </div>
      }
      btnClose={
        <div className="inline-flex w-full items-center justify-between">
          <span className="text-xs font-medium text-neutral-darker">Chiudi</span>
          <span className="h-5 w-5 fill-primary">
            <ChevronUpIcon />
          </span>
        </div>
      }
      data-cy="card-footer"
    >
      <div className="py-4">
        <div className="grid grid-rows-[1fr_auto] gap-y-4">
          <div className="grid grid-rows-[1fr_auto] gap-y-6" data-cy="text-description">
            <h3 className="text-sm font-semibold uppercase text-primary" data-cy="text-description-title">
              informazioni
            </h3>
            <p className="text-sm font-normal text-neutral-darker" data-cy="text-description-text">
              {item.description}
            </p>
          </div>
          <div className="mt-4 justify-self-center md:justify-self-end">
            <SimpleNavLink
              href={makeClinicSlug(item)}
              aria-label="Scopri tutti i servizi"
              label="Scopri tutti i servizi"
              variant="outlined"
              color="primary"
              size="small"
            />
          </div>
        </div>
      </div>
    </Collapsible>
  );
};

export default CardFooter;
