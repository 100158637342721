import Dates from '@/app/_components/availability/availabilityContainer/dates';
import Info from './info';
import Service from './service';
import Availability from '@/app/_components/availability';
import { AggregatorSpeciality, License } from '@/types-aggregatore';
import Head from 'next/head';
import OfferOtherOnlineServicesSection from './offerOtherOnlineServicesSection';
import { MetaPixelProduct, trackAddToCartEvent } from '@/utils/trackingMeta-pixel';
import { capitalizeWordsInString } from '@/utils/string';
import { screens } from '@/utils/tailwindcss';
import { useLayoutEffect, useRef, useState } from 'react';
import { jsonLdMedicalClinicFromAlgoliaClinic } from '@/app/_jsonLd/jsonLdMedicalClinic';
import { formatPrice } from '@/utils/checkout';
import { fireAddToCartEvent } from '@/utils/trackingGtm';
import { HomeIcon } from '@heroicons/react/24/outline';
import { ELBadge } from '@davincihealthcare/elty-design-system-react';
import { makeClinicSlug } from '@/utils/shared';
import { makeFullAddress } from '@/utils/common';
import { AlgoliaClinicV2 } from '@/types/algolia-types';
import { BoardSlot } from '@/app/_components/availability/availabilityContainer';
import { areSameDayTimestamps } from '@/utils/datetime';
import SimpleNavLink from '@/app/_components/simpleNavLink';

type CardBodyProps = {
  item: AlgoliaClinicV2;
};

const CardBody = ({ item }: CardBodyProps) => {
  const onAddToCart = () => {
    const metaPixelProduct: MetaPixelProduct = {
      value: item.specializations.price || 0,
      currency: 'EUR',
      content_ids: item.specializations.id || '',
      content_type: item.specializations.specialityChannels?.join(',') || '',
      content_name: item.specializations.label || '',
      content_category: item.specializations.macroSpeciality || '',
    };
    trackAddToCartEvent(metaPixelProduct);

    fireAddToCartEvent(`${item?.specializations?.price}`, {
      item_name: item?.specializations?.label,
      item_brand: item?.name,
      item_variant: item?.specializations?.label,
      item_category: item?.address,
      price: formatPrice({ amount: item?.specializations?.price }),
      index: 0,
    });
  };
  const isLicensedClinic = item?.license && (item.license as License) !== License.None;

  const [numberOfDays, setNumberOfDays] = useState(3);
  const [isLoadingAvailabilities, setIsLoadingAvailabilities] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState<BoardSlot | undefined>(undefined);
  const [hasChangedSelectedSlot, setHasChangedSelectedSlot] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const onSelectNewSlot = (newSlot: BoardSlot) => {
    if (!hasChangedSelectedSlot && selectedSlot && selectedSlot.id !== newSlot.id) {
      setHasChangedSelectedSlot(true);
    }

    setSelectedSlot(newSlot);

    if (selectedSlot && areSameDayTimestamps(newSlot.start, selectedSlot?.start)) {
      setIsLoadingAvailabilities(true);

      if (timerRef.current) clearTimeout(timerRef.current);

      timerRef.current = setTimeout(() => {
        setIsLoadingAvailabilities(false);
      }, 500);
    }
  };

  const doctorWithAvailabilities = item.specializations.doctors?.sort((a, b) => {
    const availabilityA = a.nextAvailability ?? Infinity;
    const availabilityB = b.nextAvailability ?? Infinity;
    return availabilityA - availabilityB;
  })[0];

  useLayoutEffect(() => {
    if (!window || !window.matchMedia) return;
    const handler = ({ matches: isXlScreen }: { matches: boolean }) => {
      setNumberOfDays(isXlScreen ? 4 : 3);
    };
    const mediaQuery = `(min-width: ${screens.xl}px)`;
    const media = window.matchMedia(mediaQuery);
    handler(media);
    media.addEventListener('change', handler);
    return () => media.removeEventListener('change', handler);
  }, []);

  return (
    <>
      <Head>
        <script
          key={`structured-data-${item._id}`}
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdMedicalClinicFromAlgoliaClinic(item)) }}
          type="application/ld+json"
        />
      </Head>
      <div data-cy="card-body">
        <div className="flex flex-wrap justify-start gap-8 py-2 md:gap-0 md:pt-6">
          <div className="w-full md:w-1/2">
            <div className="flex h-full flex-col gap-6">
              <Info
                clinicLogoURL={item?.logoUrl ?? ''}
                clinicName={item?.name ?? ''}
                clinicPageURL={makeClinicSlug(
                  item,
                  item?.specializations?.label ?? '',
                  item?.specializations?.id,
                  doctorWithAvailabilities?.id,
                )}
                isLicensedClinic={isLicensedClinic}
                address={makeFullAddress(item?.address, item?.cap, item?.city) ?? ''}
              />
              {item?.domiciliaryCare && (
                <ELBadge
                  Icon={HomeIcon}
                  label={`Visita a domicilio a ${capitalizeWordsInString(item.city)}`}
                  color="primary"
                  size="medium"
                />
              )}
              <OfferOtherOnlineServicesSection
                item={item}
                clinicPageURL={makeClinicSlug(item, item?.specializations?.label ?? '', item?.specializations?.id)}
              />
              <Service
                isLicensedClinic={isLicensedClinic}
                specialityItem={item.specializations as AggregatorSpeciality}
                slug={`${item?.slug}`}
                doctorWithAvailabilities={doctorWithAvailabilities}
                isLoadingAvailabilities={isLoadingAvailabilities}
                hasChangedSelectedSlot={hasChangedSelectedSlot}
                price={selectedSlot?.price ?? item.specializations?.price}
              />{' '}
              {/* todo aggregator add correct type algolia proxy */}
            </div>
          </div>
          <div className="w-full md:w-1/2">
            {!isLicensedClinic ? (
              <div className="flex h-full flex-col gap-6">
                <Dates disabled numberOfDays={numberOfDays} availableDatesTimestamps={[]} numberOfDaysNeededForPeriodSeparator={2} />
                <div className="flex h-full flex-col items-center justify-center gap-4 text-center">
                  <h4 className="text-sm font-semibold text-gray-700 lg:max-w-sm lg:text-base">
                    Al momento non è possibile prenotare online
                  </h4>
                  <SimpleNavLink
                    href={makeClinicSlug(item, item?.specializations?.label ?? '', item?.specializations?.id)}
                    aria-label="visualizza profilo"
                    label="Visualizza il profilo"
                    variant="outlined"
                    color="primary"
                    size="medium"
                  />
                </div>
              </div>
            ) : (
              <Availability
                workgroupId={item?.ambulatoryId}
                selectedSpeciality={{
                  doctorId: doctorWithAvailabilities?.id ?? '',
                  serviceId: item?.specializations?.id ?? '',
                }}
                isInCard={true}
                onAddToCart={onAddToCart}
                numberOfDays={numberOfDays}
                setExternalLoading={setIsLoadingAvailabilities}
                setExternalSlot={onSelectNewSlot}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CardBody;
