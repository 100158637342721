import { StructuredAddress } from '@/types-aggregatore';
import { getCityNameByIstatCode } from '@davincihealthcare/booking-geo';
import { PostalAddress } from 'schema-dts';

export const jsonLdPostalAddressFromStructuredAddress = (address: StructuredAddress): PostalAddress => {
  return {
    '@type': 'PostalAddress',
    addressCountry: 'IT', // todo dynamic country code by address
    addressLocality: getCityNameByIstatCode(address.cityCode),
    postalCode: address.cap ?? undefined,
    streetAddress: address.streetNumber !== undefined ? `${address.street} ${address.streetNumber}` : address.street,
  };
};
