import Container from '@/app/_components/container';
import { ELButton } from '@davincihealthcare/elty-design-system-react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { getEnumValueFromString, getStringFromQueryParam } from '@/utils/string';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { getDeliveryModeFilterFromQueryParameters } from '@/utils/search';
import OrderFilter from './orderFilter';
import { OrderType } from '@/api/algolia';

type ListingFiltersProps = {
  closeModal?: () => void;
  applyFilters: boolean;
  filterLight?: boolean;
};

export type FilterStates = {
  service: string;
  excludeOnsite: boolean;
  excludeOnline: boolean;
  excludeDomiciliary: boolean;
  order?: OrderType;
};

const ListingFilters = ({ closeModal, applyFilters, filterLight }: ListingFiltersProps) => {
  const searchParams = useSearchParams();
  const initialFilterStates: FilterStates = {
    service: '',
    excludeOnsite: false,
    excludeOnline: false,
    excludeDomiciliary: false,
    order: OrderType.DistanceAsc,
  };

  const [filterStates, setFilterStates] = useState(initialFilterStates);

  useEffect(() => {
    const deliveryFilter = getDeliveryModeFilterFromQueryParameters(searchParams?.get('exclude') ?? undefined);
    const orderFilter = getEnumValueFromString(OrderType, getStringFromQueryParam(searchParams?.get('filterOrder') ?? undefined));
    setFilterStates({
      service: getStringFromQueryParam(searchParams?.get('filterService') ?? undefined),
      excludeOnsite: deliveryFilter?.excludeOnsite || false,
      excludeOnline: deliveryFilter?.excludeOnline || false,
      excludeDomiciliary: deliveryFilter?.excludeDomicilary || false,
      order: orderFilter,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApplyFilter = () => {
    if (closeModal) closeModal();
    const newParams = new URLSearchParams(searchParams?.toString());
    newParams.delete('exclude');
    newParams.delete('filterService');
    newParams.delete('filterOrder');

    const excluded = [];
    if (filterStates.excludeOnsite) excluded.push('onsite');
    if (filterStates.excludeOnline) excluded.push('online');
    if (filterStates.excludeDomiciliary) excluded.push('domiciliary');

    if (!!excluded.length) newParams.set('exclude', excluded.join(','));
    if (filterStates.service) newParams.set('filterService', filterStates.service);
    if (filterStates.order) newParams.set('filterOrder', filterStates.order);

    window.history.pushState(null, '', `?${newParams.toString()}`);
  };

  const handleResetFilter = () => {
    if (closeModal) closeModal();
    const newParams = new URLSearchParams(searchParams?.toString());
    newParams.delete('exclude');
    newParams.delete('filterService');
    window.history.pushState(null, '', `?${newParams.toString()}`);
  };

  // TODO MOVE INTO SEPARATED COMPONENT
  const ModalFilters = () => (
    <div className="h-screen w-full max-w-xl overflow-y-auto rounded-t-md bg-white px-4 py-2 text-primary">
      <div className="flex h-full flex-col gap-y-72">
        <div>
          <div className="flex flex-row-reverse">
            <button aria-label="Chiudi" className="h-6 w-6 pt-2" onClick={handleResetFilter}>
              <XMarkIcon />
            </button>
          </div>
          <div className="mb-6 flex flex-row items-center justify-center">
            <div className="text-base font-bold text-primary">Filtri</div>
          </div>
          <div className="my-4">
            <div className="pb-2 font-semibold">Ordina per</div>
            <OrderFilter filterStates={filterStates} setFilterStates={setFilterStates} applyFilters={applyFilters} />
          </div>
        </div>
        <div className="grid grid-rows-2">
          <ELButton
            label="Applica i filtri"
            aria-label="Applica i filtri"
            variant="filled"
            color="primary"
            onClick={handleApplyFilter}
            fullWidth
          />
          <ELButton label="Annulla tutto" aria-label="Annulla tutto" variant="text" color="primary" onClick={handleResetFilter} />
        </div>
      </div>
    </div>
  );
  return (
    <>
      <Container className={`${filterLight ? 'md:bg-white' : 'md:bg-secondary-light-active'} hidden md:block`}>
        <div className="grid h-14 grid-rows-1 items-center">
          <div className="hidden md:flex md:items-center md:gap-x-8" data-cy="search-result-filter-bar">
            <span className="text-sm font-medium text-neutral-darker">Perfeziona la tua ricerca</span>
            <OrderFilter filterStates={filterStates} setFilterStates={setFilterStates} applyFilters={applyFilters} />
          </div>
        </div>
      </Container>
      {!applyFilters && <ModalFilters />}
    </>
  );
};

export default ListingFilters;
